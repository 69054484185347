
.section {
  font-family: cubano,sans-serif;
  background-color: #12181b !important;
}

.headline {
  font-family: cubano,sans-serif;
}
nav.navbar.solid.dark{
  background-color: #282a36 !important;
}
nav.navbar.solid a{
  color: #1de1da !important;
}

#small-header{
   padding-top: 120px;
}

#image-logo{
  width: 50%; 
  height: 50%;
}

.nav-image{
  width: 5%; 
  height: 5%;
}

#article-title{
  padding-top: 128px;
}
#content-section{
  padding-top: 0px;
}
.developer-name{
  color: #4fafdf;
}
#error-page{
  text-align: center;
  padding-top: 80px;
}
.title-home{
  margin-left: 0 !important;
}

#image-home{
  width: 50%; 
  margin-left: auto; 
  margin-right: auto;
}

.buffer-image{
  width: 50%; 
  height: 50%;
}

/* .text-description {
  color: #444;
} */

.blog-body h4{
  font-weight: bold !important;
}
.blog-body p {
  font-size: 1.6rem !important;
}
.contact-us-container{
  margin-top: 40px; 
}
#guide{
  background-color: black !important;
}
.sidenav-trigger{
  cursor: pointer;
}
.blog-related li .title{
  font-weight: bold !important;
}

.center{
  font-weight: bold;
}

.blog-body p {
  color: #bdbdbd !important;
}

.all-border-radius{
  border-radius: 25px 25px 25px 25px !important;
}

.top-border-radius{
  border-radius: 25px 25px 0 0 !important;
}

.bottom-border-radius{
  border-radius: 0 0 25px 25px !important;
}

.card-action{
  background-color: #282a36 !important;
}

.card-content{
  background-color: #282a36 !important;
}

.card{
  background-color: #282a36 !important;
}